import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';

import { icons } from 'admin-kit/assets/icons'
import app_icons from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'

import * as moment from 'moment'
import 'moment/locale/sv';
// TODO: update for user locale
moment.locale('sv');


React.icons = {...icons, ...app_icons}

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
