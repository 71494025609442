import React from "react";

import {
  RelatedLink,
  DetailLink,
  Thumbnail,
  SmallThumbnail,
  PlayerActionButtons,
  Checkbox,
  DetailActions,
  Tags,
} from "admin-kit/components/cells";

import {
  DateTimeFormat,
  FromNow,
  PublishedState,
  ContentStatus,
  UserAvatar,
  LanguageIcons,
  AccountList,
} from "./components";

const GenericList = React.lazy(() => import("admin-kit/resources/base/list"));
const GenericDetail = React.lazy(() =>
  import("admin-kit/resources/base/detail")
);
const SupportPage = React.lazy(() =>
  import("admin-kit/resources/support/index")
);
const SupportDetailPage = React.lazy(() =>
  import("admin-kit/resources/support/detail")
);

const ContentListPage = React.lazy(() => import("./resources/content/list"));
const ContentDetailPage = React.lazy(() =>
  import("./resources/content/detail")
);

const Dashboard = React.lazy(() => import("./resources/dashboard"));

const CONTENT_TYPE_TABS = [
  { resource: "content-types", label: "Content types" },
  { resource: "content-modules", label: "Module library" },
];

const ACCOUNT_TABS = [
  { resource: "accounts", label: "Accounts" },
  { resource: "languages", label: "Languages" },
];

const SITE_TABS = [
  { resource: "site-settings", label: "Site settings" },
  { resource: "site-module-templates", label: "Site templates" },
  { resource: "site-webhook-logs", label: "Webhook logs" },
];

const resources = [
  {
    name: "contents",
    label: "Content",
    icon: "",
    path: "content-by-type/:content_type_slug",
    list: {
      component: ContentListPage,
      columns: [
        { name: "image", label: "Image", component: SmallThumbnail },
        { name: "name", label: "Title", component: DetailLink },
        { name: "parent.title", label: "Parent" },
        { name: "status", label: "State", component: ContentStatus },
        { name: "languages", label: "Languages", component: LanguageIcons },

        { name: "content_type.name", label: "Content type" },
        { name: "updated_at", label: "Updated at", component: FromNow },
        { name: "created_by", label: "Created by", component: UserAvatar },
      ],
      filters: [
        { name: "archived_at_isnull", label: "Archived", type: "boolean" },
      ],
      static_filters: {
        archived_at_isnull: "false",
      },
    },

    detail: {
      editComponent: ContentDetailPage,
      createComponent: ContentDetailPage,

      actions: [{ name: "duplicate", label: "Duplicate" }],

      // fields: [
      //   // {name: "name", label: "Name", type: "text"},
      // ]
    },
  },

  {
    name: "content-types",
    label: "Content types",
    icon: "",

    list: {
      tabs: CONTENT_TYPE_TABS,
      columns: [{ name: "name", label: "Name", component: DetailLink }],
      filters: [],
    },

    detail: {
      drawer: "template_fields",
      layout: {
        Basic: {
          "": ["name", "slug"],
          "Modules from library": ["content_modules"],
          Fields: ["config"],
        },
        Settings: {
          "": [
            "repeatable",
            "tree_structure",
            "ordering",
            "ordering_field",
            "title_field",
            "image_field",
          ],
        },
      },
    },
  },

  {
    name: "content-modules",
    label: "Content modules",
    icon: "",

    list: {
      tabs: CONTENT_TYPE_TABS,
      columns: [{ name: "name", label: "Name", component: DetailLink }],
      filters: [],
    },

    detail: {
      drawer: "template_fields",
    },
  },

  {
    name: "site-settings",
    label: "Site settings",
    icon: "",

    list: {
      tabs: SITE_TABS,
      columns: [{ name: "name", label: "Name", component: DetailLink }],
      filters: [],
    },

    detail: {},
  },

  {
    name: "site-webhook-logs",
    label: "Webhook logs",
    icon: "",

    list: {
      tabs: SITE_TABS,
      columns: [
          { name: "id", label: "#"},
          { name: "action", label: "action"},
          { name: "site_webhook_name", label: "site_webhook_name"},
          { name: "response_time", label: "response_time"},
          { name: "status", label: "status"},
          { name: "status_code", label: "status_code"},
          { name: "tries", label: "tries"},
          { name: "created_at", label: "created_at", component: DateTimeFormat},
          { name: "updated_at", label: "updated_at", component: DateTimeFormat},

        ],
      filters: [],
    },

    detail: {},
  },

  {
    name: "site-module-templates",
    label: "Templates",
    icon: "",

    list: {
      tabs: SITE_TABS,
      columns: [{ name: "name", label: "Name", component: DetailLink }],
      filters: [],
    },

    detail: {},
  },

  {
    name: "media",
    label: "Media",
    icon: "",

    list: {
      columns: [
        { name: "image", label: "Image", component: SmallThumbnail },
        { name: "name", label: "Name", component: DetailLink },
        { name: "default_alt_text", label: "Alt" },
        { name: "file_type", label: "Type" },
        { name: "extension", label: "Extension" },
      ],
      filters: [],
    },

    detail: {
      layout: {
        "": {
          "": ["name", "default_alt_text"],
        },
      },
    },
  },

  {
    name: "accounts",
    label: "Accounts",
    icon: "",

    list: {
      tabs: ACCOUNT_TABS,
      columns: [{ name: "name", label: "Name", component: DetailLink }],
      filters: [],
    },

    detail: {
      actions: [
        { name: "copy_from_account", label: "Copy from source account" },
        { name: "primeflow_setup", label: "Setup primeflow template" },
        { name: "web_setup", label: "Setup web template" },
      ],
      layout: {
        Basic: {
          "": ["name"],
          Language: ["base_language", "extra_languages"],
          "Account copy": ["account_copy_source", "account_copy_update"],
        },
      },
    },
  },

  {
    name: "languages",
    label: "Languages",
    icon: "",

    list: {
      tabs: ACCOUNT_TABS,
      columns: [
        { name: "name", label: "Name", component: DetailLink },
        { name: "code", label: "Code" },
      ],
      filters: [],
    },

    detail: {},
  },
  {
    name: "users",
    label: "Users",
    icon: "",

    list: {
      columns: [
        { name: "email", label: "Email", component: DetailLink },
        { name: "accounts", label: "Accounts", component: AccountList },
        { name: "username", label: "Username (legacy)" },
        { name: "account.name", label: "Account (legacy)" },
      ],
      filters: [],
    },

    detail: {
      actions: [{ name: "su_login", label: "Login as user" }],
    },
  },
];

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
];

for (let resource of resources) {
  routes.push({
    path: `/${resource.path || resource.name}`,
    exact: true,
    resource: resource,
    name: resource.label,
    component: resource.list.component || GenericList,
  });
  routes.push({
    path: `/${resource.path || resource.name}/create`,
    exact: true,
    resource: resource,
    name: "Create",
    component: resource.detail.createComponent || GenericDetail,
  });
  routes.push({
    path: `/${resource.path || resource.name}/:id`,
    exact: false,
    resource: resource,
    name: resource.label,
    component: resource.detail.editComponent || GenericDetail,
  });
}

routes.push({
  path: `/support`,
  exact: true,
  name: "Support",
  component: SupportPage,
});
routes.push({
  path: `/support/:id`,
  exact: true,
  name: "Support",
  component: SupportDetailPage,
});

export default routes;
