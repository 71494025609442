import React, { Component, useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";

import { AuthContext, useAuth } from "./context/auth";

import axios from "axios";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Layout = React.lazy(() => import("./containers/layout"));

const Login = React.lazy(() => import("./resources/auth/login"));
const PasswordReset = React.lazy(() =>
  import("./resources/auth/password-reset")
);

const PasswordResetConfirm = React.lazy(() =>
  import("./resources/auth/password-reset-confirm")
);

const SwitchAccount = React.lazy(() =>
  import("./resources/auth/switch-account")
);

const App = ({ routes, nav }) => {
  const [user, setUser] = useState(null);

  const navFiltered = React.useMemo(() => {
    if (!user) {
      return [];
    }
    let newNav = [];

    for (let menu of nav) {
      let newMenu = { ...menu };

      if (menu._children) {
        newMenu._children = [];

        for (let submenu of menu._children) {
          if (
            (submenu.auth &&
              user.permissions[submenu.auth] &&
              user.permissions[submenu.auth].change) ||
            !submenu.auth
          ) {
            let newSubmenu = { ...submenu };
            newMenu._children.push(newSubmenu);
          }
        }

        if (menu.contentTypes && user.content_types) {
          delete newMenu.contentTypes;
          for (let contentType of user.content_types) {
            newMenu._children.push({
              _tag: "CSidebarNavItem",
              name: contentType.name,
              to: `/content-by-type/${contentType.slug}`,
            });
          }
        }

        if (newMenu._children.length) {
          // Only add if not empty
          newNav.push(newMenu);
        }
      } else if (
        (menu.auth &&
          user.permissions[menu.auth] &&
          user.permissions[menu.auth].change) ||
        !menu.auth
      ) {
        newNav.push(newMenu);
      }
    }
    return newNav;
  }, [nav, user]);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />

            <Route
              exact
              path="/password-reset"
              name="Forgot password"
              render={(props) => <PasswordReset {...props} />}
            />

            <Route
              exact
              path="/password-reset/confirm/:uid/:token"
              name="New password"
              render={(props) => <PasswordResetConfirm {...props} />}
            />

            <Route
              exact
              path="/switch-account"
              name="Switch account"
              render={(props) =>
                user ? (
                  <SwitchAccount {...props} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { referer: props.location },
                    }}
                  />
                )
              }
            />

            <Route
              path="/"
              name="Home"
              render={(props) =>
                user ? (
                  <Layout {...props} routes={routes} nav={navFiltered} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { referer: props.location },
                    }}
                  />
                )
              }
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </AuthContext.Provider>
  );
};

export default App;
