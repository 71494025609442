import React from 'react';

import AdminApp from "admin-kit/app"

import './scss/style.scss';

import nav from "./nav"
import routes from "./routes"

const App = (props)=>{

  return <AdminApp {...props} nav={nav} routes={routes}/>

}

export default App
