
import {

  cilCircle,
  cilAsteriskCircle,
  cilChevronCircleRightAlt,
  cilBan,
  cilCheckCircle,

  // cisCheckCircle,
  // cisXCircle,
  cilEnvelopeOpen,
  cilPrint,
  cilChatBubble,
  cilCog,
  cilFile,
  cilClock,
  cilReload,
  cilCopy,
  cilClipboard,
} from '@coreui/icons'


export default {
  cilCircle,
  cilAsteriskCircle,
  cilChevronCircleRightAlt,
  cilBan,
  cilCheckCircle,
  // cisCheckCircle,
  // cisXCircle,
  cilEnvelopeOpen,
  cilPrint,
  cilChatBubble,
  cilCog,
  cilFile,
  cilClock,
  cilReload,
  cilCopy,
  cilClipboard,
}
