import { createStore } from 'redux'


import {handleToast} from "admin-kit/store"

const initialState = {
  sidebarShow: 'responsive',

  toasts: [],
  toastId: 0,

  drawerShow: null,
  user: null,
  blockConfig: null,

  //
  editor: null,
  editorState: null,


}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }

    case 'ADD_TOAST':
      return handleToast(state, type, rest)


    case "EDITOR":
      return {...state, editor: rest.editor}

    case "EDITORSTATE":
      return {...state, editorState: rest.editorState}

    default:
      return state
  }
}

const store = createStore(changeState)
export default store
