

const handleToast = (state, type, rest) =>{


      const now = new Date().getTime()
      const toastId = state.toastId + 1

      // Keep toast array from getting to long by only keeping recent toasts
      const toasts = state.toasts.filter((toast) => {
        return (now - toast.timestamp) < 10000
      })

      const toast = rest.toast
      toast.timestamp = now
      toast.id = toastId
      toasts.push(toast)

      return {...state, toasts, toastId}

}

export {handleToast}
