import React from "react";

import CIcon from "@coreui/icons-react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";

import service from "../../service";
import { useSelector, useDispatch } from "react-redux";

import {
  Link,
  NavLink,
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from "react-router-dom";

const RelatedLink = ({ resource, value, row, column }) => {
  return value ? (
    <Link to={`/${column.resource}/${row[column.related_name].id}`}>
      {value}
    </Link>
  ) : null;
};

const DetailLink = ({ resource, value, row }) => {
  const match = useRouteMatch();

  return <Link to={`${match.url}/${row.id}`}>{value || "-"}</Link>;
};

const Thumbnail = ({ resource, value, row }) => {
  return (
    <div className="media-thumbnail">
      <img src={value} />
    </div>
  );
};

const SmallThumbnail = ({ resource, value, row }) => {
  return (
    <div className="media-thumbnail sm">
      <img src={value} />
    </div>
  );
};

const PlayerActionButtons = ({ resource, value, row }) => {
  return (
    <div className="d-flex">
      <button className="btn btn-outline-primary btn-sm">
        <CIcon name={"cil-cloud-upload"} size="sm" className="mr-1" /> Publish
      </button>
    </div>
  );
};

const ActionButton = ({
  name,
  label,
  resource,
  row,
  className,
  BtnComponent,
  triggerReload,
  setParentLoading,
  disabled,
  confirm,
}) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const callAction = async (event) => {
    if (disabled) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (loading) {
      return;
    }

    if (confirm && !window.confirm(label)) {
      return;
    }

    if (setParentLoading) {
      setParentLoading(true);
    }

    setLoading(true);
    let message, messageType;

    try {
      let response = await service.action_detail(resource.name, name, row.id);

      if (response.data && response.data.detail) {
        message = response.data.detail;
      } else {
        message = `${label} success`;
      }

      messageType = "success";

      if (triggerReload) {
        triggerReload();
      }
    } catch (er) {
      messageType = "danger";
      console.log(er);
      if (er.response && er.response.data && er.response.data.detail) {
        message = er.response.data.detail;
      } else {
        message = `${label} error`;
      }
    }

    dispatch({
      type: "ADD_TOAST",
      toast: { message: message, type: messageType },
    });

    if (setParentLoading) {
      setParentLoading(false);
    }
    setLoading(false);
  };

  return (
    <BtnComponent
      onClick={callAction}
      disabled={loading || disabled}
      className={className}
    >
      {loading && (
        <span className="spinner spinner-border spinner-border-sm mr-2" />
      )}
      {label}
    </BtnComponent>
  );
};

const DetailActions = ({ resource, value, row, triggerReload }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <div className="d-flex">
      <CDropdown>
        <CDropdownToggle caret={false} color="link">
          {loading ? (
            <span className="spinner spinner-border spinner-border-sm" />
          ) : (
            <CIcon name="cil-options" />
          )}
        </CDropdownToggle>
        <CDropdownMenu>
          {resource.detail.actions.map(({ name, label, confirm }, index) => (
            <ActionButton
              key={index}
              name={name}
              label={label}
              confirm={confirm}
              resource={resource}
              row={row}
              triggerReload={triggerReload}
              setParentLoading={setLoading}
              className={""}
              BtnComponent={CDropdownItem}
            />
          ))}
        </CDropdownMenu>
      </CDropdown>
    </div>
  );
};

const Checkbox = ({ resource, value, row }) => {
  return (
    <div className="d-flex">
      {value ? (
        <span className="badge badge-success">
          <CIcon name="cil-check" />
        </span>
      ) : (
        <span className="badge badge-danger">
          <CIcon name="cil-x" />
        </span>
      )}
    </div>
  );
};

const Tags = ({ resource, value, row }) => {
  return (
    <div className="d-flex">
      {value &&
        value.map((tag, index) => (
          <span className="badge badge-dark mr-1" key={index}>
            {tag}
          </span>
        ))}
    </div>
  );
};

export {
  RelatedLink,
  DetailLink,
  Thumbnail,
  PlayerActionButtons,
  Checkbox,
  DetailActions,
  ActionButton,
  Tags,
  SmallThumbnail,
};
