import React from "react";
import * as moment from "moment";

import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";

import FLAGS from "../assets/flags";

const DateTimeFormat = ({ resource, value, row }) => {
  return <span>{moment(value).format("L LT")}</span>;
};

const FromNow = ({ resource, value, row }) => {
  return <span>{moment(value).fromNow()}</span>;
};

const UserAvatar = ({ resource, value, row }) => {
  let name;
  if (value) {
    name = value.charAt(0).toUpperCase();
  } else {
    name = "-";
  }

  return (
    <span className="bg-dark text-light user-avatar" title={value}>
      {name}
    </span>
  );
};

const ContentStatus = ({ resource, value, row }) => {
  return (
    <div className="content-status">
      {value.is_published && (
        <span className="badge badge-success">Published</span>
      )}

      {value.has_draft && !value.is_archived && (
        <span className="badge badge-warning">Draft</span>
      )}

      {value.is_archived && <span className="badge badge-dark">Archive</span>}

      {value.is_start_page && (
        <span className="badge badge-primary">Start page</span>
      )}
      {value.releases &&
        value.releases.map((release, index) => {
          return (
            <span key={index} className="badge badge-release">
              {release}
            </span>
          );
        })}
    </div>
  );
};

const PublishedState = ({ resource, value, row }) => {
  return (
    <>
      {value ? (
        <span className="badge badge-success">
          <CIcon className="text-light" name="cil-check" />
        </span>
      ) : (
        <span className="badge badge-danger">
          <CIcon className="text-light" name="cil-x" />
        </span>
      )}
      {row.is_dirty && (
        <span className="badge badge-warning ml-1">
          <CIcon className="text-light" name="cil-reload" />
        </span>
      )}
    </>
  );
};

const FlagComponent = ({ language, fallback }) => {

  const FlagIcon = FLAGS[language.code];
  if (FlagIcon) {
    return <FlagIcon />;
  }
  return fallback || null;
};

const LanguageIcons = ({ resource, value, row }) => {
  return (
    <div className="languages">
      {value &&
        value.filter(language=>!language.is_hidden).map((language, index) => (
          <div key={index} style={{opacity: language.has_uid ? 1 : .5}}>
            <FlagComponent language={language} fallback={language.code} />
          </div>
        ))}
    </div>
  );
};

const AccountList = ({ resource, value, row }) => {
  return value ? value.map((account) => account.name).join(", ") : "-";
};

export {
  AccountList,
  DateTimeFormat,
  FromNow,
  ContentStatus,
  PublishedState,
  UserAvatar,
  LanguageIcons,
  FlagComponent,
};
