import React from "react";

import { ReactComponent as FLAG_DK } from "./dk.svg";
import { ReactComponent as FLAG_EU } from "./eu.svg";
import { ReactComponent as FLAG_FI } from "./fi.svg";
import { ReactComponent as FLAG_GB } from "./gb.svg";
import { ReactComponent as FLAG_IS } from "./is.svg";
import { ReactComponent as FLAG_NO } from "./no.svg";
import { ReactComponent as FLAG_SE } from "./se.svg";

const GlobeIcon = ({}) => {
  return <i className="fa fa-globe mr-1" />;
};

export default {
  "en-gb": FLAG_GB,
  sv: FLAG_SE,
  nb: FLAG_NO,
  da: FLAG_DK,
  fi: FLAG_FI,
  "": GlobeIcon,
};
