export default [

  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer'
  },


  {
    _tag: 'CSidebarNavDropdown',
    name: 'Content',
    icon: 'cil-view-module',
    contentTypes: true,
    _children: [

    ],
  },



    {
      _tag: 'CSidebarNavItem',
      name: 'Media',
      icon: 'cil-image',
      to: "/media"
    },



  {
    _tag: 'CSidebarNavDropdown',
    name: 'Settings',
    icon: 'cil-settings',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Content types',
        to: '/content-types',
        auth: 'content-types',
      },

      {
        _tag: 'CSidebarNavItem',
        name: 'Site',
        to: '/site-settings',
        auth: 'site-settings',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Users',
        to: '/users',
        auth: 'users',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Accounts',
        to: '/accounts',
        auth: 'accounts',
      }
    ],
  },

  {
    _tag: 'CSidebarNavDivider',
    className: 'mt-5'
  },



  {
    _tag: 'CSidebarNavItem',
    name: 'Support',
    to: '/support',
    icon: 'cil-speech',
  },

]
